import { makeAutoObservable } from 'mobx'
import { Api, MockApi } from '../Services'

export default class ShiftStore {
  rootStore
  shifts = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  categories = [] // (Kustannusyksiköt) Lempi, Tampere; Lempi, Turku etc.

  shift = null
  loading = null


  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setShifts = (shifts) => { this.shifts = shifts }
  setTotal = (total) => { this.total = total }
  setShift = (shift) => { this.shift = shift }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }
  setCategories = (categories) => { this.categories = categories }

  async getShifts(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 'all') ? params.category : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getShifts({ skip, take, search, category, sort })
    if (response.ok) {
      this.setShifts(response.data.items)
      this.setTotal(response.data.total)
      this.setCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getShift(id) {
    this.setLoading(true)
    const response: any = await Api.getShift(id)
    if (response.ok) {
      this.setShift(response.data)
    }
    this.setLoading(false)
  }
}

import apisauce from 'apisauce'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/',
  Stage: 'https://lempi-api.stage.geniem.io/',
  Production: 'https://lempi-api.production.geniem.io/'
}

let currentApi = API_URLS.Production
if (window.location.hostname.includes('.stage.geniem.io')) currentApi = API_URLS.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) currentApi = API_URLS.Local

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

const create = (baseURL = currentApi) => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL,
    timeout: 20000
  })

  return {
    // Auth
    login: (data) => api.post('auth/login', data),
    logout: () => api.put('auth/logout'),
    logoutOthers: () => api.put('auth/logout-others'),
    keepAuthAlive: () => api.put('auth/keep-alive'),
    requestPasswordReset: (data) => api.post('auth/password-reset', data),
    completePasswordResetWithCode: (data) => api.put('auth/password-reset', data),

    // Analytics
    getCustomersSummary: (date) => api.get('analytics/customer-summary', { date }),
    getEmployeesSummary: (date) => api.get('analytics/employees-summary', { date }),

    // Polls
    getPolls: (params) => api.get('poll', params),
    getPoll: (id) => api.get(`poll/${id}`),
    createPoll: (poll) => api.post('poll', poll),
    updatePoll: (id, poll) => api.put(`poll/${id}`, poll),

    // Shifts
    getShifts: (params) => api.get('shift', params),
    getShift: (shiftId) => api.get(`shift/${shiftId}`),

    // Notifications
    getNotifications: (params) => api.get('notification', params),
    getNotification: (id) => api.get(`notification/${id}`),
    createNotification: (data) => api.post(`notification`, data),
    updateNotification: (id, data) => api.put(`notification/${id}`, data),

    // Feedbacks
    getGeneralFeedbacks: (params) => api.get('feedback/general-feedback', params),
    getShiftFeedbacks: (params) => api.get('feedback/shift-feedback', params),

    // User
    getMe: () => api.get('user/me'),
    updateMe: (data) => api.put('user/me', data),
    updateMyPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post('user/me/photo', formData, options)
    },
    updateUserPhoto: (userId, data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`/user/my/${userId}/photo`, formData, options)
    },
    changePassword: (data) => api.put('user/me/password', data),
    createUser: (data) => api.post('user', data),
    deactivateUser: (id: number) => api.delete(`user/${id}`),
    getUser: (id) => api.get(`user/${id}`),
    getUsers: () => api.get('user'),

    // Customers
    getCustomers: (params) => api.get('customer', params),

    // Employees
    getEmployees: (params) => api.get('employee', params),
    getEmployee: (id) => api.get(`employee/${id}`),

    // Files
    getVideoEmbed: (url) => api.get('media/video-embed', { url }),

    uploadPhoto: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }
      return api.post(`file/photo`, formData, options)
    }
  }
}

const Api = create()

export default Api

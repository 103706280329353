import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class SessionStore {
  rootStore

  token = null
  user = null
  loading = null
  isLoggedIn = false
  initialized = false
  emailChange = null
  emailVerification = null
  passwordReset = null
  changePasswordSuccess = false
  keepAliveStarted = false
  requestPasswordResetSent = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateToken = (token) => { this.token = token }
  updateUser = (user) => { this.user = user }
  updateLoading = (loading) => { this.loading = loading }
  updateIsLoggedIn = (isLoggedIn) => { this.isLoggedIn = isLoggedIn }
  updateInitialized = (initialized) => { this.initialized = initialized }
  updateEmailChange = (emailChange) => { this.emailChange = emailChange }
  updateEmailVerification = (verification) => { this.emailVerification = verification }
  updatePasswordReset = (passwordReset) => { this.passwordReset = passwordReset }
  updateChangePasswordSuccess = (success) => { this.changePasswordSuccess = success }
  updateKeepAliveStarted = (keepAlive) => { this.keepAliveStarted = keepAlive  }
  updateRequestPasswordResetSent = (sent) => { this.requestPasswordResetSent = sent }

  async login(email, password) {
    this.updateLoading(true)
    const response: any = await Api.login({ email, password })
    this.rootStore.appStore.setErrorOrSuccess(response, 'wrong_username_or_password', false)
    if (response.ok) {
      await this.getMe()
      const user = response.data.user
      this.updateUser(user)
      /*
      this.updateIsLoggedIn(true)
      this.startKeepAlive()
      */
    }
    this.updateLoading(false)
  }

  async logout() {
    this.updateLoading(true)
    const response = await Api.logout()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.updateIsLoggedIn(false)
    }
  }

  async requestPasswordReset(email) {
    this.updateLoading(true)
    const response = await Api.requestPasswordReset({ email })
    if (response.ok) {
      this.updateRequestPasswordResetSent(true)
    }
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async completePasswordReset(code, password) {
    this.updateLoading(true)
    const response = await Api.completePasswordResetWithCode({ code, password })
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
  }

  async getMe() {
    this.updateLoading(true)
    const response = await Api.getMe()
    this.updateLoading(false)
    if (response.ok) {
      const user = response.data
      this.updateUser(user)
      this.updateIsLoggedIn(true)
      this.startKeepAlive()
    }
    this.updateInitialized(true)
  }

  async updateMe(data) {
    this.updateLoading(true)
    const response = await Api.updateMe(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'information_updated')
    if (response.ok) {
      await this.getMe()
    }
  }

  async updateMyPhoto(data) {
    this.updateLoading(true)
    const response = await Api.updateMyPhoto(data)
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'information_updated')
    if (response.ok) {
      await this.getMe()
    }
  }

  async createEmailChange(email) {
    this.updateLoading(true)
    const response = await Api.createEmailChange({ email })
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'check_inbox_to_complete_email_change')
    this.updateLoading(false)
  }

  async getEmailChange(code) {
    this.updateLoading(true)
    this.updateEmailChange(null)
    const response = await Api.getEmailChange(code)
    this.updateLoading(false)
    // this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', false)
    if (response.ok) {
      const emailChange = response.data
      this.updateEmailChange(emailChange)
    }
  }

  async verifyEmailChange(code) {
    this.updateLoading(true)
    const response = await Api.verifyEmailChange({ code })
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', 'email_changed')
  }

  async changeEmail(email) {
    this.updateLoading(true)
    this.updateLoading(false)
  }

  async changePassword(currentPassword, newPassword, newPasswordAgain) {
    this.updateChangePasswordSuccess(false)

    if (!currentPassword || !newPassword || !newPasswordAgain) {
      return this.rootStore.appStore.setError('fill_all_fields')
    }
    else if (newPassword.length < 8) {
      return this.rootStore.appStore.setError('password_too_weak')
    } else if (newPassword !== newPasswordAgain) {
      return this.rootStore.appStore.setError('passwords_do_not_match')
    }
    this.updateLoading(true)
    const response = await Api.changePassword({ currentPassword, newPassword })
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, 'check_fields_and_try_again', 'password_changed')

    if (response.ok) {
      this.updateChangePasswordSuccess(true)

      // Logout other devices
      await Api.logoutOthers()
    }
  }

  async getUserInvite(code) {
    this.updateLoading(true)
    const response = await Api.getUserInvite(code)
    this.updateLoading(false)
    // this.rootStore.appStore.setErrorOrSuccess(response, 'invalid_or_expired_code', false)
    if (response.ok) {
      this.updateEmailVerification(true)
    }
  }

  async verifyUserInvite(code, password, callback) {
    this.updateLoading(true)
    const response = await Api.verifyUserInvite({ code, password })
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, true)
    if (response.ok) {
      callback()
    }
  }

  runKeepAlive = async () => {
    if (this.isLoggedIn && this.initialized) {
      const result = await Api.keepAuthAlive()
      if (result.status === 401) {
        this.updateUser(null)
        this.updateIsLoggedIn(false)
      }
    }
  }

  async startKeepAlive() {
    if (!this.keepAliveStarted) {
      this.updateKeepAliveStarted(true)
      setTimeout(this.runKeepAlive, 1000)

      setInterval(() => {
        this.runKeepAlive()
      }, 1000 * 60 * 30) // Run every 30 minutes
    }
  }
}

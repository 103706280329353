import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, Input, Select, Dialog } from '..'
import { useTranslation } from 'react-i18next'
import OrderUpIcon from '../../Assets/Icons/order-up.svg'
import OrderDownIcon from '../../Assets/Icons/order-down.svg'
import DeleteIcon from '../../Assets/Icons/delete.svg'
import CloseIcon from '../../Assets/Icons/close-thin.svg'
import { IconButton } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '1rem',
    marginBottom: '2rem',
    position: 'relative'
  },
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.5rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  categoryContainer: {
    flex: 2,
    marginRight: '1.25rem'
  },
  linkContainer: {
    flex: 3,
    marginLeft: '1.25rem'
  },
  mediaButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  horizontalSpacer: {
    width: '1.5rem'
  },
  actionButtons: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  actionButtonIcon: {
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  actionButton: {
    margin: 0,
    padding: '.5rem 0',
    borderRadius: 5,
    width: '2.25rem',
    height: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '.75rem',
    cursor: 'pointer',
    background: Colors.lightGreen,
    border: `1px solid ${Colors.border}`,
    boxShadow: `inset .1875rem .1875rem 0 0 ${Colors.white}, .1875rem .1875rem 0 0 ${Colors.lightGreen}`
  },
  optionsContainer: {
    border: `1px solid ${Colors.navy}`,
    padding: '0 .5rem 2rem 1.5rem',
    borderRadius: '.625rem'
  },
  h3: {
    color: Colors.navy,
    fontSize: '1.25rem'
  },
  pollTypeContainer: {
    width: '17.75rem'
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: '1rem'
  },
  closeIcon: {
    height: '1.75rem'
  },
  deleteOptionButton: {
    marginLeft: '.25rem'
  }
}))

export default function PollForm(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    lang
  } = props

  const [confirmDeleteQuestion, setConfirmDeleteQuestion] = useState<any>(null)
  const [confirmDeleteQuestionOption, setConfirmDeleteQuestionOption] = useState<any>(null)

  useEffect(() => {
    if (props.mode === 'create') {
      addQuestion()
    }
  }, [])

  const addQuestion = () => {
    props.setQuestions([
      ...props.questions,
      {
        titleFi: '',
        titleEn: '',
        type: 'select',
        options: [
          {
            nameFi: '',
            nameEn: ''
          },
          {
            nameFi: '',
            nameEn: ''
          }
        ]
      }
    ])
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const setTitle = (lang, index, val) => {
    props.setQuestions([...props.questions.map((q, idx) => {
      if (index !== idx) return q
      return {
        ...q,
        ...(lang === 'en' ? { titleEn: val } : { titleFi: val })
      }
    })])
  }

  const setType = (index, val) => {
    props.setQuestions([...props.questions.map((q, idx) => {
      if (index !== idx) return q
      return {
        ...q,
        type: val
      }
    })])
  }

  const moveQuestion = (index, direction) => {
    const newQuestions = [...props.questions]

    if (direction === 'up') {
      if (index === 0) return
      const temp = newQuestions[index - 1]
      newQuestions[index - 1] = newQuestions[index]
      newQuestions[index] = temp
    } else if (direction === 'down') {
      if (index === newQuestions.length - 1) return
      const temp = newQuestions[index + 1]
      newQuestions[index + 1] = newQuestions[index]
      newQuestions[index] = temp
    }

    props.setQuestions(newQuestions)
  }

  const deleteQuestion = (index) => {
    if (!confirmDeleteQuestion) {
      setConfirmDeleteQuestion({ index })
    } else {
      props.setQuestions([...props.questions.filter((q, idx) => idx !== index)])
      setConfirmDeleteQuestion(null)
    }
  }

  const setQuestionOptionName = (questionIndex, optionIndex, lang, value) => {
    props.setQuestions([...props.questions].map((question, qIdx) => {
      if (qIdx !== questionIndex) return question
      return {
        ...question,
        options: question.options.map((option, oIdx) => {
          if (oIdx !== optionIndex) return option
          return {
            ...option,
            ...(lang === 'en' ? { nameEn: value } : { nameFi: value})
          }
        })
      }
    }))
  }

  const deleteQuestionOption = (questionIndex, optionIndex) => {
    if (!confirmDeleteQuestionOption) {
      setConfirmDeleteQuestionOption({ questionIndex, optionIndex })
    } else {
      props.setQuestions([...props.questions].map((question, qIdx) => {
        if (qIdx !== questionIndex) return question
        return {
          ...question,
          options: question.options.filter((option, oIdx) => {
            if (oIdx !== optionIndex) return true
            return false
          })
        }
      }))
      setConfirmDeleteQuestionOption(null)
    }
  }

  const addAnswerOption = (questionIndex) => {
    props.setQuestions([...props.questions.map((item, idx) => {
      if (idx !== questionIndex) return item
      return {
        ...item,
        options: [...(item.options || []), {
          nameFi: '',
          nameEn: ''
        }]
      }
    })])
  }

  const renderArrangeButtons = (index) => {
    const isLastParentQuestion = index === (props.questions.length - 1)
    return (
      <>
        {(index > 0) ? (
          <div
            onClick={() => moveQuestion(index, 'up')}
            className={classes.actionButton}
          >
            <img
              src={OrderUpIcon}
              alt='up'
              className={classes.actionButtonIcon}
            />
          </div>
        ) : null}
        {!isLastParentQuestion ? (
          <div
            onClick={() => moveQuestion(index, 'down')}
            className={classes.actionButton}
          >
            <img
              src={OrderDownIcon}
              alt='down'
              className={classes.actionButtonIcon}
            />
          </div>
        ) : null}
      </>
    )
  }

  const renderDeleteButton = (index) => {
    return (
      <div
        onClick={() => deleteQuestion(index)}
        className={classes.actionButton}
      >
        <img
          src={DeleteIcon}
          alt='delete'
          className={classes.actionButtonIcon}
        />
      </div>
    )
  }

  const renderHeaderButtons = (index) => {
    return (
      <div className={classes.actionButtons}>
        {renderArrangeButtons(index)}
        {renderDeleteButton(index)}
      </div>
    )
  }

  const renderQuestionOptions = (questionIndex) => {
    const questionType = props.questions[questionIndex].type
    if (questionType === 'text') return null

    const options = props.questions[questionIndex].options || []
    return (
      <div className={classes.optionsContainer}>
        <h3 className={classes.h3}>{t('answer_options')}</h3>
        {options.map((option, optionIndex) => {
          return (
            <div className={classes.optionRow}>
              <Input
                value={props.lang === 'en' ? option.nameEn : option.nameFi}
                onChange={(val) => setQuestionOptionName(questionIndex, optionIndex, props.lang, val)}
                label={`${t('answer_option')} ${optionIndex + 1}.`}
                fullWidth
                noMargin
                {...validationMessage(props.lang === 'en' ? option.nameEn : option.nameFi)}
              />
              <IconButton
                onClick={() => deleteQuestionOption(questionIndex, optionIndex)}
                className={classes.deleteOptionButton}
                size='large'
              >
                <img src={CloseIcon} className={classes.closeIcon} alt='icon' />
              </IconButton>

            </div>
          )
        })}
        <Button
          text={t('add_answer')}
          onClick={() => addAnswerOption(questionIndex)}
          outlined
        />
      </div>
    )
  }

  const renderQuestions = () => {
    return props.questions.map((question, index) => {
      return (
        <div className={classes.questionContainer}>
          {renderHeaderButtons(index)}
          <h3 className={classes.h3}>{`${t('question')} ${index + 1}.`}</h3>
          <Input
            label={t('question')}
            value={props.lang === 'en' ? question.titleEn : question.titleFi}
            onChange={(val) => setTitle(lang, index, val)}
            {...validationMessage(props.lang === 'en' ? question.titleEn : question.titleFi)}
          />
          <div className={classes.pollTypeContainer}>
            <Select
              options={[
                { label: t('poll_type_select'), value: 'select' },
                { label: t('poll_type_multiselect'), value: 'multiselect' },
                { label: t('poll_type_text'), value: 'text' }
              ]}
              value={question.type}
              onChange={(val) => setType(index, val)}
              label={t('poll_type')}
            />
          </div>
          {renderQuestionOptions(index)}
        </div>
      )
    })
  }

  const renderConfirmDialog = () => {
    if (!confirmDeleteQuestionOption && !confirmDeleteQuestion) return null

    if (confirmDeleteQuestionOption) {
      return (
        <Dialog
          open
          handleClose={() => setConfirmDeleteQuestionOption(null)}
          handleOk={() => deleteQuestionOption(confirmDeleteQuestionOption.questionIndex, confirmDeleteQuestionOption.optionIndex)}
          title={t('confirm_delete_question_option')}
          description={t('confirm_delete_question_option_description')}
          okText={t('yes')}
        />
      )
    }
    if (confirmDeleteQuestion) {
      return (
        <Dialog
          open
          handleClose={() => setConfirmDeleteQuestion(null)}
          handleOk={() => deleteQuestion(confirmDeleteQuestion.index)}
          title={t('confirm_delete_question')}
          description={t('confirm_delete_question_description')}
          okText={t('yes')}
        />
      )
    }
  }

  return (
    <div>
      <div className={classes.row}>
        <Input
          value={props.lang === 'en' ? props.nameEn : props.nameFi}
          onChange={props.lang === 'en' ? props.setNameEn : props.setNameFi}
          label={t('poll_name')}
          fullWidth
          {...validationMessage(props.lang === 'en' ? props.nameEn : props.nameFi)}
        />
        <div className={classes.horizontalSpacer} />
        <div className={classes.pollTypeContainer}>
          <Select
            options={props.categoryOptions}
            value={props.category}
            onChange={props.setCategory}
            label={t('poll_category')}
          />
        </div>
      </div>
      <Input
        value={props.lang === 'en' ? props.titleEn : props.titleFi}
        onChange={props.lang === 'en' ? props.setTitleEn : props.setTitleFi}
        label={t('poll_title')}
        fullWidth
        {...validationMessage(props.lang === 'en' ? props.titleEn : props.titleFi)}
      />
      <Input
        value={props.video}
        onChange={props.setVideo}
        label={t('video_embed_url')}
        fullWidth
      />
      {renderQuestions()}
      {renderConfirmDialog()}
      <Button
        text={t('add_question')}
        onClick={addQuestion}
      />
    </div>
  )
}

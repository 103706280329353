import { makeAutoObservable } from 'mobx'
import { Api, MockApi } from '../Services'

export default class CustomerStore {
  rootStore
  customers = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  categories = []

  customer = null
  loading = null


  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setCustomers = (customers) => { this.customers = customers }
  setTotal = (total) => { this.total = total }
  setCustomer = (customer) => { this.customer = customer }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }
  setCategories = (categories) => { this.categories = categories }

  async getCustomers(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 'all') ? params.category : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getCustomers({ skip, take, search, category, sort })
    if (response.ok) {
      this.setCustomers(response.data.items)
      this.setTotal(response.data.total)
      this.setCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getShift(id) {
    this.setLoading(true)
    const response: any = await MockApi.getCustomer(id)
    if (response.ok) {
      this.setCustomer(response.data)
    }
    this.setLoading(false)
  }
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageContainer, PageHeader, PageActions, PageContent, NotificationForm, Checkbox, Input, Button } from '../../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'
import { Lang } from '../../../Constants'
import { editorToHtml, htmlToEditor } from '../../../Components/Common/EditorInput/editor.transform'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    padding: '2.5rem'
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  main: {
    flex: 1
  },
  sidebar: {
    marginTop: '4rem'
  },
  box: {
    border: `1px solid ${Colors.border}`,
    borderRadius: '.625rem',
    padding: '2rem'
  },
  h3: {
    margin: '0 0 1.5rem',
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1',
    textTransform: 'uppercase'
  },
  publishTimeContainer: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    width: '1rem',
    height: '1rem'
  },
  cancelButton: {
    border: 0,
  },
  cancelButtonText: {
    fontWeight: 700
  }
}))

enum PublishType {
  now = 'now',
  draft = 'draft',
  scheduled = 'scheduled'
}

function Notification() {
  const classes = useStyles()

  const { sessionStore, notificationStore, appStore }: any = useStore()
  const navigate = useNavigate()

  const { notificationId } = useParams()

  const { t } = useTranslation()

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState<any>(null)
  const [contentEn, setContentEn] = useState<any>(null)
  const [linkFi, setLinkFi] = useState('')
  const [linkEn, setLinkEn] = useState('')
  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [video, setVideo] = useState(null)

  const [category, setCategory] = useState('general')

  const [lang, setLang] = useState(Lang.fi)

  const [publishType, setPublishType] = useState(PublishType.scheduled)

  const [publishTime, setPublishTime] = useState(moment().add(90, 'minutes').startOf('hour').format('HH:mm'))
  const [publishDate, setPublishDate] = useState(moment().add(90, 'minutes').startOf('hour').format('YYYY-MM-DD'))

  useEffect(() => {
    notificationStore.getNotification(notificationId)
  }, [])

  useEffect(() => {
    if (notificationStore.notification && notificationStore.notification.id === Number(notificationId)) {
      const noti = notificationStore.notification
      setTitleFi(noti.titleFi)
      setTitleEn(noti.titleEn)
      setContentFi(htmlToEditor(noti.contentHtmlFi))
      setContentEn(htmlToEditor(noti.contentHtmlEn))
      setLinkFi(noti.linkFi)
      setLinkEn(noti.linkEn)
      setPhoto(noti.photo)
      setVideo(noti.video)
      setCategory(noti.category || noti.type)

      setPublishTime(moment(noti.publishedAt).format('HH:mm'))
      setPublishDate(moment(noti.publishedAt).format('YYYY-MM-DD'))
    }
  }, [notificationStore.notification])

  const handleCancel = () => {
    navigate(-1)
  }

  const handleSave = () => {
    let notification: any = {
      type: category,
      senderName: `${sessionStore.user?.firstName} ${sessionStore.user.lastName}`.trim()
    }

    if (publishType === PublishType.draft) {
      notification.draft = true
      notification.publishedAt = null
    } else if (publishType === PublishType.now) {
      notification.publishedAt = moment().add(30, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    } else {
      // Specific time
      const date = moment(publishDate).format('YYYY-MM-DD')
      const time = publishTime
      notification.publishedAt = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    }

    notification = {
      ...notification,
      titleFi,
      titleEn,
      contentHtmlFi: editorToHtml(contentFi),
      contentHtmlEn: editorToHtml(contentEn)
    }

    notificationStore.updateNotification(notificationId, notification)
  }

  const getPublicationOptions = () => {
    return [
      {
        label: t('immediately'),
        value: PublishType.now
      },
      {
        label: t('save_as_draft'),
        value: PublishType.draft
      },
      {
        label: t('as_scheduled'),
        value: PublishType.scheduled
      }
    ]
  }

  return (
    <PageContainer>
      <div className={classes.rootContainer}>
        <div className={classes.main}>
          <PageHeader
            title={t('edit_push_notification')}
            description={t('send_new_push_notificaiton_description')}
            lang={lang}
            setLang={setLang}
          />
          <PageContent size='medium'>
            <NotificationForm
              category={category}
              setCategory={setCategory}
              lang={lang}
              titleFi={titleFi}
              setTitleFi={setTitleFi}
              titleEn={titleEn}
              setTitleEn={setTitleEn}
              contentFi={contentFi}
              contentEn={contentEn}
              setContentFi={setContentFi}
              setContentEn={setContentEn}
              linkFi={linkFi}
              setLinkFi={setLinkFi}
              linkEn={linkEn}
              setLinkEn={setLinkEn}
              photo={photo}
              setPhoto={setPhoto}
              newPhoto={newPhoto}
              setNewPhoto={setNewPhoto}
              video={video}
              setVideo={setVideo}
            />
          </PageContent>
        </div>
        <div className={classes.sidebar}>
          <PageActions
            user={sessionStore.user}
            logout={() => sessionStore.logout()}
          >
            <h3 className={classes.h3}>{t('publication')}</h3>
            <div className={classes.box}>
              {getPublicationOptions().map(item => {
                return (
                  <div>
                    <Checkbox
                      label={item.label}
                      checked={publishType === item.value}
                      onChange={() => setPublishType(item.value)}
                      radio
                    />
                  </div>
                )
              })}
              <div className={classes.publishTimeContainer}>
                <Input
                  value={publishDate}
                  onChange={setPublishDate}
                  type='date'
                />
                <div className={classes.spacer} />
                <Input
                  value={publishTime}
                  onChange={setPublishTime}
                  type='time'
                />
              </div>
            </div>
            <div className={classes.spacer} />
            <Button
              text={t('save')}
              onClick={handleSave}
              fullWidth
            />
            <div className={classes.spacer} />
            <Button
              text={t('cancel')}
              onClick={handleCancel}
              outlined
              fullWidth
              buttonStyle={classes.cancelButton}
              buttonTextStyle={classes.cancelButtonText}
            />
          </PageActions>
        </div>
      </div>
    </PageContainer>
  )
}

export default observer(Notification)

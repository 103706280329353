import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class ShiftFeedbackStore {
  rootStore
  shiftFeedbacks = []
  categories= []

  skip = 0
  take = 50
  total = 0 // Number of total results

  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setShiftFeedbacks = (shiftFeedbacks) => { this.shiftFeedbacks = shiftFeedbacks }
  setTotal = (total) => { this.total = total }
  setCategories = (categories) => { this.categories = categories }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }

  async getShiftFeedbacks(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 'all') ? params.category : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getShiftFeedbacks({ skip, take, search, category, sort })
    if (response.ok) {
      this.setShiftFeedbacks(response.data.items)
      this.setTotal(response.data.total)
    }
    this.setLoading(false)
  }
}

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from '../../Layouts'
import Dashboard from './Dashboard/Dashboard'
import UserAnalytics from './Analytics/UserAnalytics'
import UsageAnalytics from './Analytics/UsageAnalytics'
import Notifications from './Notification/Notifications'
import CreateNotification from './Notification/CreateNotification'
import Shifts from './Shift/Shifts'
import Customers from './Customer/Customers'
import Notification from './Notification/Notification'
import Settings from './Settings/Settings'
import Users from './User/Users'
import Polls from './Poll/Polls'
import PollResults from './Poll/PollResults'
import CreatePoll from './Poll/CreatePoll'
import Poll from './Poll/Poll'
import Employees from './Employees/Employees'
import GeneralFeedbacks from './Feedback/GeneralFeedbacks'
import ShiftFeedbacks from './Feedback/ShiftFeedbacks'

export default function Authorized(props) {
  // const isSuperAdmin = props.user && props.user.role === Role.SuperAdmin
  return (
    <AppLayout>
      <Routes>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/user-analytics' element={<UserAnalytics />} />
        <Route path='/usage-analytics' element={<UsageAnalytics />} />
        <Route path='/shifts' element={<Shifts />} />
        <Route path='/customers' element={<Customers />} />
        <Route path='/general-feedbacks' element={<GeneralFeedbacks />} />
        <Route path='/shift-feedbacks' element={<ShiftFeedbacks />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/notifications/create' element={<CreateNotification />} />
        <Route path='/notifications/:notificationId' element={<Notification />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/users' element={<Users />} />
        <Route path='/polls' element={<Polls />} />
        <Route path='/poll-results/:pollId' element={<PollResults />} />
        <Route path='/polls/create' element={<CreatePoll />} />
        <Route path='/polls/:pollId' element={<Poll />} />
        <Route path='/employees' element={<Employees />} />

        <Route path='*' element={<Navigate replace to={'/dashboard'} />} />
      </Routes>
    </AppLayout>
  )
}

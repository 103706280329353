import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '1.875rem',
    fontWeight: 700,
    color: Colors.black
  },
  footerSpacer: {
    height: '.5rem'
  }
}))

export default function BaseDialog(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='dialog-title'
        aria-describedby='dialog-description'
      >
        <DialogTitle id='dialog-title' className={classes.title}>{props.title}</DialogTitle>
        <DialogContent>
          {props.description ? (
            <DialogContentText id='dialog-description'>
              {props.description}
            </DialogContentText>
          ) : null}
          <>
            {props.children}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            {props.dismissText || t('cancel')}
          </Button>
          <Button
            onClick={props.handleOk}
            disabled={props.okDisabled}
            color='primary'
            autoFocus
          >
            {props.okText || t('ok')}
          </Button>
        </DialogActions>
        <div className={classes.footerSpacer} />
      </Dialog>
    </div>
  )
}

import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, Input, Select, EditorInput } from '..'
import { useTranslation } from 'react-i18next'
import { Lang } from '../../Constants'
import MediaInput from '../Common/MediaInput'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.5rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.label
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  categoryContainer: {
    flex: 2,
    marginRight: '1.25rem'
  },
  linkContainer: {
    flex: 3,
    marginLeft: '1.25rem'
  },
  mediaButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  horizontalSpacer: {
    width: '1.5rem'
  }
}))

export default function NotificationForm(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [mediaType, setMediaType] = useState((props.video ? 'video' : '') || (props.photo ? 'photo' : '') || '')

  const handleDeleteVideo = () => {
    setMediaType('')
    setVideo(null)
    setPhoto(null)
  }

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setVideo(null)
      setMediaType('photo')
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setVideo(null)
    setMediaType('')
    setNewPhoto(null)
  }


  const {
    titleFi,
    setTitleFi,
    titleEn,
    setTitleEn,
    category,
    setCategory,
    linkFi,
    setLinkFi,
    linkEn,
    setLinkEn,
    contentFi,
    contentEn,
    setContentFi,
    setContentEn,
    photo,
    setPhoto,
    newPhoto,
    setNewPhoto,
    video,
    setVideo,
    lang
  } = props

  const getCategoryOptions = () => {
    return [
      {
        label: t('notification_type_general'),
        value: 'general'
      },
      {
        label: t('notification_type_campaign'),
        value: 'campaign'
      },
      {
        label: t('notification_type_marketing'),
        value: 'marketing'
      },
      {
        label: t('notification_type_reminder'),
        value: 'reminder'
      }
    ]
  }

  return (
    <>
      <Input
        value={lang === Lang.en ? titleEn : titleFi}
        onChange={lang === Lang.en ? setTitleEn : setTitleFi}
        label={t('message_title')}
      />
      <div className={classes.row}>
        <div className={classes.categoryContainer}>
          <Select
            value={category}
            onChange={setCategory}
            options={getCategoryOptions()}
            label={t('category')}
          />
        </div>
        <div className={classes.linkContainer}>
          <Input
            value={lang === Lang.en ?  linkEn : linkFi}
            onChange={lang === Lang.en ? setLinkEn : setLinkFi}
            label={t('link')}
          />
        </div>
      </div>
      <p className={classes.label}>{t('content')}</p>
      <EditorInput
        placeholder={t('placeholder_content')}
        value={lang === Lang.en ? contentEn : contentFi}
        onChange={lang === Lang.en ? setContentEn : setContentFi}
      />
      <MediaInput
        video={video}
        setVideo={setVideo}
        handleDeleteVideo={handleDeleteVideo}
        photo={photo}
        handleSetPhoto={handleSetPhoto}
        handleRemovePhoto={handleRemovePhoto}
        mediaType={mediaType}
        setMediaType={setMediaType}
      />
    </>
  )
}

import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { TableHeader, DataMessage, Checkbox } from '..'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../Utils/theme'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  tableOuterContainer: {
    borderLeft: `1px solid ${Colors.border}`,
    borderRight: `1px solid ${Colors.border}`
  },
  root: {
    cursor: 'pointer',
    backgroundColor: Colors.white,
    '&:hover': {
      backgroundColor: `${Colors.tableHover} !important`
    },
    borderBottom: `1px solid ${Colors.border}`
  },
  cell: {
    fontFamily: 'Ambit',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: Colors.black,
    borderBottom: 'none',
    height: '4.25rem'
  },
  secondary: {
    color: Colors.secondaryText
  },
  checkboxContainer: {
    paddingLeft: '.25rem',
    maxWidth: '2rem'
  }
}))

function ItemTableRow (props) {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const openItem = () => null

  const renderTimeSpan = () => {
    const startMoment = moment(item.startTime)
    const endMoment = moment(item.endTime)

    const weekday = t(`day_short_${startMoment.isoWeekday()}`)
    let startDay = startMoment.format('DD.MM.')
    const year = startMoment.format('YYYY')
    if (year !== moment().format('YYYY')) {
      // Add year suffix if not on current year
      startDay = `${startDay}${year}`
    }
    const startTime = startMoment.format('HH:mm')
    const endTime = endMoment.format('HH:mm')
    return `${weekday} ${startDay} ${startTime} - ${endTime}`
  }

  const renderDuration = () => {
    const startMoment = moment(item.startTime)
    const endMoment = moment(item.endTime)
    const hoursDiff = endMoment.diff(startMoment, 'minutes') / 60
    return `${hoursDiff.toFixed(2).replace('.', ',')}h`
  }

  return (
    <TableRow
      classes={{ root: classes.root }}
      onClick={openItem}
      hover
    >
      <TableCell className={classes.cell}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.selectedIds.includes(item.id)}
            onChange={() => props.toggleSelect(item.id)}
            label=''
          />
        </div>
      </TableCell>
      {[
        renderTimeSpan(),
        renderDuration(),
        item?.customerName,
        item?.taskName,
        item?.profitUnit,
      ].map((content, index) => {
        return (
          <TableCell key={index} className={classes.cell} onClick={openItem}>
            <span className={index ? classes.secondary : ''}>{content}</span>
          </TableCell>
        )
      })}
    </TableRow>

  )
}

function ShiftTable(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRows = () => {
    if (props.items) {
      return props.items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemTableRow
            item={item}
            onSelect={() => null}
            selectedIds={props.selectedIds}
            toggleSelect={props.toggleSelect}
          />
        </React.Fragment>
      ))
    }
    return null
  }

  const isAllSelected = () => {
    if (!props.selectedIds.length || props.selectedIds.length < props.items.length) return false
    for (const item of props.items) {
      if (!props.selectedIds.includes(item.id)) {
        return false
      }
    }
    return true
  }

  const toggleAll = () => {
    const all = isAllSelected()
    if (all) {
      props.clearSelect()
    } else {
      // Add new items to selection
      props.selectAll(props.items.map(item => item.id))
    }
  }


  return (
    <div className={classes.tableOuterContainer}>
      <Table>
        <TableHeader
          columnNames={[t('the_time'), t('duration'), t('customer'), t('task'), t('profit_unit')]}
          sort={props.sort}
          sortKeys={props.sortKeys}
          onSort={props.onSort}
          onSelect={toggleAll}
          selected={isAllSelected()}
        />
        <TableBody>{renderRows()}</TableBody>
      </Table>
      <DataMessage
        loading={props.loading}
        data={props.items}
        message={t('no_results')}
      />
    </div>
  )
}

export default ShiftTable

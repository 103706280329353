import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, Input, Select } from '..'
import { useTranslation } from 'react-i18next'
import SearchIcon from '../../Assets/Icons/search.svg'
import FilterIcon from '../../Assets/Icons/filter.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${Colors.border}`,
    padding: '1.25rem 1rem 1.25rem',
    borderTopLeftRadius: '.625rem',
    borderTopRightRadius: '.625rem'
  },
  searchContainer: {
    width: '100%',
    maxWidth: '32rem',
    marginRight: '2rem'
  },
  filterButton: {
    border: `1px solid ${Colors.border}`,
    minWidth: '8rem'
  },
  flex: {
    flex: 1
  },
  massActionsContainer: {
    marginLeft: '2rem',
    marginRight: '2rem',
    width: '100%',
    maxWidth: '12rem'
  },
  limiterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  resultCount: {
    whiteSpace: 'no-wrap'
  },
  limitSelectContainer: {
    padding: '0 .5rem'
  }
}))

export default function TableHeaderActions(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSetPerPage = (value) => {
    props.setPerPage(value)
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <Input
          value={props.search}
          onChange={props.setSearch}
          placeholder={t('search')}
          iconLeft={SearchIcon}
          fullWidth
          noMargin
        />
      </div>
      <Button
        text={t('filter')}
        leftIcon={FilterIcon}
        onPress={props.onSearch}
        buttonStyle={classes.filterButton}
        outlined
      />
      <div className={classes.flex} />
      <div className={classes.massActionsContainer}>
        <Select
          options={[{ label: t('mass_actions'), value: 'all' }]}
          value={'all'}
          noMargin
        />
      </div>
      <div className={classes.limiterContainer}>
        {t('show')}
        <div className={classes.limitSelectContainer}>
          <Select
            options={[
              { label: '10', value: 10 },
              { label: '50', value: 50 },
              { label: '100', value: 100 }
            ]}
            value={props.perPage}
            onChange={handleSetPerPage}
            style={{ height: '2rem' }}
            noMargin
            mini
          />
        </div>
        <div className={classes.resultCount}>
          {t('num_results')}{' / '}{props.total || ''}
        </div>
      </div>
    </div>
  )
}

import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class NotificationStore {
  rootStore
  notifications = []
  notification = null
  categories = []
  creatorUsers = null
  loading = null
  total = 0

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setNotifications = (notifications) => { this.notifications = notifications }
  setNotification = (notification) => { this.notification = notification }
  setTotal = (total) => { this.total = total }

  async getNotifications(params) {
    this.setLoading(true)
    const response: any = await Api.getNotifications(params)
    if (response.ok) {
      this.setNotifications(response.data.items)
      this.setTotal(response.data.total)
    }
    this.setLoading(false)
  }

  async getNotification(id) {
    this.setLoading(true)
    const response: any = await Api.getNotification(id)
    if (response.ok) {
      this.setNotification(response.data)
    }
    this.setLoading(false)
  }

  async createNotification(notification) {
    this.setLoading(true)
    const response: any = await Api.createNotification(notification)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'push_notification_created')
    this.setLoading(false)
  }

  async updateNotification(id, notification) {
    this.setLoading(true)
    const response: any = await Api.updateNotification(id, notification)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    this.setLoading(false)
  }
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { useStore } from '../../../Models/RootStore'
import { PageContainer, PageHeader, PageActions, PageContent, Button } from '../../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'
import { Lang } from '../../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.125rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1'
  },
  topDivider: {
    marginTop: '-1rem',
    marginBottom: '1.5rem',
    borderTop: `1px solid ${Colors.lightBorder}`
  },
  pollSummary: {
    marginTop: '2rem',
    marginBottom: '2rem',
    borderTop: `2px solid ${Colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.navy
  },
  pollSummaryCol: {
    color: Colors.white,
    padding: '1rem 1.25rem',
    fontWeight: 700
  },
  pollSummaryDivider: {
    width: '1px',
    background: Colors.border
  },
  lightTitle: {
    textTransform: 'uppercase',
    fontWeight: 300,
    Color: Colors.text
  },
  h3: {
    color: Colors.navy
  },
  content: {
    color: Colors.text
  },
  questionContainer: {
    marginTop: '2rem',
    borderTop: `1px solid ${Colors.border}`,
    borderBottom: `1px solid ${Colors.border}`,
    padding: '1rem 0'
  },
  charBox: {
    backgroundColor: Colors.navy,
    color: Colors.white,
    fontWeight: 700,
    borderRadius: '.325rem',
    display: 'inline-block',
    padding: '.5rem',
    minWidth: '3rem',
    textAlign: 'center',
    marginRight: '1rem'
  },
  flex: {
    flex: 1
  },
  optionContainer: {
    backgroundColor: Colors.lightGreen,
    padding: '.5rem 1rem .5rem .5rem',
    borderRadius: '.25rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

enum PublishType {
  now = 'now',
  draft = 'draft',
  scheduled = 'scheduled'
}

enum ExpireType {
  notSet = 'not_set',
  scheduled = 'scheduled'
}


function PollResults() {
  const classes = useStyles()

  const { sessionStore, pollStore }: any = useStore()
  const navigate = useNavigate()

  const { pollId } = useParams()

  const [lang, setLang] = useState(Lang.fi)
  const { t } = useTranslation()

  useEffect(() => {
    pollStore.getPoll(pollId)
  }, [])

  const handleCancel = () => {
    navigate(-1)
  }

  const poll = pollStore.poll

  // TODO: loading spinner
  if (!poll) return null


  const renderSummary = () => {
    const fields = [
      `${t('category')} - ${t(`poll_type_${poll.type}`)}`,
      `${t('open_between')} ${moment(poll.validFrom || poll.createdAt).format(`DD.MM.${poll.validUntil ? '' : 'YYYY'}`)} - ${poll.validUntil ? moment(poll.validUntil).format('DD.MM.YYYY') : ''}`,
      `${t('view_times')}: 0 ${t('pcs')}`,
      `${t('num_answers')} ${poll.totalAnswers} ${t('pcs')}`,
      `${t('conversion')} 0%`
    ]
    return (
      <div className={classes.pollSummary}>
        {fields.map((field, index) => {
          return (
            <>
              <div className={classes.pollSummaryCol}>{field}</div>
              {index !== fields.length -1 && <div className={classes.pollSummaryDivider} />}
            </>
          )
        })}
      </div>
    )
  }

  const indexToChar = (index) => "ABCDEFGHIJKLMNOPQRSTUVWXYZ".slice(index, index + 1)

  const renderQuestionAnswers = (question) => {
    if (question.type !== 'text') {
      return (question.options || []).map((option, index) => {
        return (
          <div className={classes.optionContainer}>
            <div className={classes.charBox}>{indexToChar(index)}</div>
            {lang === Lang.fi ? (option.nameFi || option.nameEn) : (option.nameEn || option.nameFi)}
            <div className={classes.flex} />
            {`0% (0 ${t('num_answers_alt')})`}
          </div>
        )
      })
    }
  }

  const renderQuestion = (question, index) => {
    return (
      <div className={classes.questionContainer}>
        <div className={classes.lightTitle}>{t('question')} {`#${index + 1}`}</div>
        <h3 className={classes.h3}>{lang === Lang.fi ? (question.titleFi || question.titleEn) : (question.titleEn || question.titleFi)}</h3>
        <p className={classes.content}>{lang === Lang.fi ? (question.contentFi || question.contentEn) : (question.contentEn || question.contentFi)}</p>
        {renderQuestionAnswers(question)}
      </div>
    )
  }

  const renderQuestions = () => {
    return poll.questions.map((item, index) => {
      return renderQuestion(item, index)
    })
  }

  return (
      <PageContainer>
      <PageHeader
        lang={lang}
        setLang={setLang}
        user={sessionStore.user}
        logout={() => sessionStore.logout()}
        onBackToList={handleCancel}
      />
      <PageContent>
        <div className={classes.topDivider} />
        <h1 className={classes.title}>{lang === Lang.fi ? (poll.nameFi || poll.nameEn) : (poll.nameEn || poll.nameFi)}</h1>
        {renderSummary()}
        <div className={classes.lightTitle}>{t('introduction')}</div>
        <h3 className={classes.h3}>{lang === Lang.fi ? (poll.titleFi || poll.titleEn) : (poll.titleEn || poll.titleFi)}</h3>
        <p className={classes.content}>{lang === Lang.fi ? (poll.contentFi || poll.contentEn) : (poll.contentEn || poll.contentFi)}</p>
        {renderQuestions()}
      </PageContent>
    </PageContainer>
  )
}

export default observer(PollResults)

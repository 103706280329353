import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import 'dayjs/locale/fi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Button, HeaderUser, Tabs } from '../'
import { Lang } from '../../Constants'
import DownArrowIcon from '../../Assets/Icons/down-arrow.svg'
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Button as MuiButton } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3.5rem 3rem 0',
    position: 'relative'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    margin: 0,
    fontFamily: 'Ambit',
    fontSize: '2.125rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1'
  },
  description: {
    padding: 0,
    marginTop: '2rem',
    color: Colors.text,
    fontSize: '1.125rem',
    marginBottom: 0
  },
  flex: {
    flex: 1
  },
  noDescriptionSpacer: {
    height: '1rem'
  },
  tabActive: {
    cursor: 'pointer',
    backgroundColor: Colors.navy,
    color: Colors.white,
    borderRadius: '.3125rem',
    padding: '.5rem 2rem'
  },
  tab: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: Colors.navy,
    borderRadius: '.3125rem',
    padding: '.5rem 2rem'
  },
  tabsContainer: {
    paddingLeft: '1rem',
    //paddingRight: '2rem'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '.25rem',
    position: 'relative',
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  datePickerDash: {
    fontSize: '2.125rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1'
  },
  datePickerOuterContainer: {
    marginTop: '-1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '.5rem'
  },
  dateText: {
    fontSize: '2.125rem',
    color: Colors.navy,
    textShadow: `3px 3px ${Colors.lightGreen}`,
    lineHeight: '1.1',
    fontWeight: 400
  },
  arrowDownIcon: {
    marginLeft: '.8rem',
    marginTop: '1rem',
    height: '.5rem'
  },
  headerUserContainer: {
    paddingLeft: '2rem',
    maxWidth: '20rem'
  }
}))

export default function PageHeader(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const toggleDatePickerOpen = () => setDatePickerOpen(!datePickerOpen)
  const dateInputRef = useRef()

  const renderUser = () => {
    if (props.user) {
      return (
        <div className={classes.headerUserContainer}>
          <HeaderUser
            user={props.user}
            logout={props.logout}
          />
        </div>
      )
    }
  }

  const renderDescription = () => {
    if (props.description) {
      return <p className={classes.description}>{props.description}</p>
    }
    return <div className={classes.noDescriptionSpacer} />
  }

  const renderLang = () => {
    if (props.setLang) {
      return (
        <div className={classes.tabsContainer}>
          <Tabs
            items={[{ name: t('in_finnish'), value: 'fi' }, { name: t('in_english'), value: 'en' }]}
            value={props.lang}
            onChange={props.setLang}
            extraPadding
          />
        </div>
      )
    }
    return null
  }

  const renderTabs = () => {
    if (props.setTab && props.tab && props.tabs) {
      return (
        <div className={classes.tabsContainer}>
          <Tabs
            items={props.tabs}
            value={props.tab}
            onChange={props.setTab}
            extraPadding
          />
        </div>
      )

    }
    return null
  }

  const handleSetDate = (date) => {
    setDatePickerOpen(false)
    props.setDate(date.format('YYYY-MM-DD'))
  }

  const renderDateInput = (params) => {
    console.log(params)
    return (
      <>
        <div className={classes.row}><div className={classes.dateText}>-</div></div>
        <MuiButton
          onClick={toggleDatePickerOpen}
        >
          <div
            ref={dateInputRef}
            className={classes.dateContainer}
          >
            <div className={classes.dateText}>
              {moment(props.date).format('DD.MM.YYYY')}
            </div>
            <img src={DownArrowIcon} className={classes.arrowDownIcon} />
          </div>
        </MuiButton>
      </>
    )
  }

  const renderDatePicker = () => {
    if (props.setDate) {
      return (
        <div className={classes.datePickerOuterContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
            <DesktopDatePicker
              inputFormat="DD.MM.YYYY"
              value={props.date}
              onChange={handleSetDate}
              open={datePickerOpen}
              PopperProps={{ anchorEl: dateInputRef.current }}
              renderInput={(params) => renderDateInput(params)}
            />
          </LocalizationProvider>
        </div>
      )
    }
    return null
  }

  const renderBackToList = () => {
    if (props.onBackToList) {
      return (
        <Button
          text={t('back_to_list')}
          onClick={props.onBackToList}
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {renderBackToList()}
        {props.title && <h1 className={classes.title}>{props.title}</h1>}
        {renderDatePicker()}
        <div className={classes.flex} />
        {renderTabs()}
        {renderLang()}
        {renderUser()}
      </div>
      {renderDescription()}
    </div>
  )
}

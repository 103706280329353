import { makeAutoObservable } from 'mobx'
import { Api, MockApi } from '../Services'

export default class EmployeeStore {
  rootStore
  employees = []
  skip = 0
  take = 50
  total = 0 // Number of total results
  categories = []

  employee = null
  loading = null


  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setLoading = (loading) => { this.loading = loading }
  setEmployees = (employees) => { this.employees = employees }
  setTotal = (total) => { this.total = total }
  setEmployee = (employee) => { this.employee = employee }
  setSkip = (skip) => { this.skip = skip }
  setTake = (take) => { this.take = take }
  setCategories = (categories) => { this.categories = categories }

  async getEmployees(params) {
    const search = params.search || ''
    const take = params.perPage || 50
    const skip = (params.page || 0) * take
    const category = (params.category !== 'all') ? params.category : undefined
    const sort = params.sort || undefined

    this.setLoading(true)
    const response: any = await Api.getEmployees({ skip, take, search, category, sort })
    if (response.ok) {
      this.setEmployees(response.data.items)
      this.setTotal(response.data.total)
      this.setCategories(response.data.categories)
    }
    this.setLoading(false)
  }

  async getEmployee(id) {
    this.setLoading(true)
    const response: any = await MockApi.getEmployee(id)
    if (response.ok) {
      this.setEmployee(response.data)
    }
    this.setLoading(false)
  }
}
